<template>
  <div class="layout_common menu_manage">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left wrap">
            <inputComp
              placeholder="订单号"
              :hval="orderId"
              @blur="orderBlur"
              style="width:210px"
            />
            <inputComp placeholder="设备序列号" :hval="deviceId" @blur="deviceIdBlur" />
            <inputComp placeholder="企业名称" :hval="companyCustomerName" @blur="companyCustomerNameBlur" />
            <inputComp placeholder="客户下单人姓名" :hval="customerName" @blur="customerNameBlur" />
            <inputComp style="width:170px" placeholder="收货人手机号" :hval="phone" @blur="phoneBlur" />
            <inputComp style="width:170px" placeholder="认证手机号" :hval="authPhone" @blur="authPhoneBlur" />
            <selectComp placeholder="是否全新" :hval="goodsGrade" :data="goodsGradeList" @change="goodsGradeChange" />
            <dateComp :hval="date" @change="dateChange" />
            <btnGroup @search="search" @reset="reset" />
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
          <el-table-column label="订单号" slot="orderId">
            <template v-slot="scope">
              <router-link v-permission="'详情'" target="_blank"
                :to="{ name: 'order-list-detail', query: { orderId: scope.row.orderId } }"
              >
                {{ scope.row.orderId }}
              </router-link>
              <div v-if="!flag">{{scope.row.orderId}}</div>
            </template>
          </el-table-column>
          <el-table-column label="商品名称" slot="goodsName">
            <template v-slot="scope">
              <a :href="`https://www.zugeqifu.com/goodsDetail/${scope.row.goodsSn}`" target="_blank">{{scope.row.goodsName}}</a>
            </template>
          </el-table-column>
          <el-table-column label="租赁模式(租赁周期)" slot="lease">
            <template v-slot="scope">
              {{`${handleLeaseWay(scope.row.leaseWay)}(${scope.row.period?scope.row.period:'无'})`}}
            </template>
          </el-table-column>
          <el-table-column label="订单状态" slot="orderStatus">
            <template v-slot="scope">
              <span :style="{color:scope.row.orderStatus>3&&scope.row.orderStatus<7 || scope.row.orderStatus===12 ?'red':''}">
                {{ handleOrderStatus(scope.row.orderStatus) }}
              </span>
            </template>
          </el-table-column>
           <el-table-column label="起租日期(到期日期)" slot="date">
            <template v-slot="scope">
              <div>{{handleDate(scope.row.leaseBeginDate)}}</div>
              <div>{{handleDate(scope.row.leaseEndDate)}}</div>
            </template>
          </el-table-column>
          <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" @click="repairs(scope.row)" v-permission="'报修'"
                >报修</btnComp>
              <div>
                <btnComp btnType="text" @click="onCompensate(scope.row,'add')" v-permission="'客户补偿记录'"
                >客户补偿记录</btnComp>
              </div>
              <div>
                <btnComp btnType="text" @click="onErrorOrder(scope.row,'add')" v-permission="'有误订单记录'"
                >有误订单记录</btnComp>
              </div>
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <add :isShow="addShow" @close="close" :info="info" />
    <compensateAdd :isShow="compensateAddShow" @close="close" :info="info" :dialogType="dialogType" />
    <errorOrderAdd :isShow="errorOrderAddShow" @close="close" :info="info" :dialogType="dialogType" />
  </div>
</template>

<script>
import add from './add'
import compensateAdd from '../compensate/add'
import errorOrderAdd from '../error-order/add'
import { orderList, checkHaveHandleOrder, storeModeWarn, getOrderTypeBySkuId } from '@/api'
import { formatDate, orderStatus } from '@/utils/common'
import { btnPermission } from '@/utils/directive'
export default {
  components: {
    add,
    compensateAdd,
    errorOrderAdd
  },
  data () {
    return {
      loading: false,
      addShow: false,
      compensateAddShow: false,
      errorOrderAddShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      info: {},
      dialogType: '', // 弹窗类型
      orderId: '',
      deviceId: '',
      customerName: '',
      companyCustomerName: '',
      phone: '',
      authPhone: '',
      goodsGrade: '',
      beginStartDate: '',
      beginEndDate: '',
      date: [],
      goodsGradeList: [
        { label: '全新', value: 1 },
        { label: '非全新', value: 0 }
      ],
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { slot: 'orderId' },
        { prop: 'customerName', label: '企业名称' },
        { slot: 'orderStatus' },
        { slot: 'goodsName' },
        { prop: 'goodsDetail', label: '配置' },
        { prop: 'leaseQuantity', label: '下单数量' },
        { slot: 'num' },
        { slot: 'lease' },
        { slot: 'date' },
        { prop: 'currentSell', label: '销售' }
      ]
    }
  },
  mounted () {
    // this.getList()
  },
  computed: {
    // 来判断是否有详情权限
    flag () {
      return btnPermission(this.$store.state.menuList, this.$route.path, '详情')
    }
  },
  methods: {
    getList () {
      const params = {
        page: this.curPage,
        size: this.pageSize,
        orderId: this.orderId,
        deviceId: this.deviceId,
        customerName: this.customerName,
        companyCustomerName: this.companyCustomerName,
        phone: this.phone,
        authPhone: this.authPhone,
        goodsGrade: this.goodsGrade,
        beginStartDate: this.beginStartDate,
        beginEndDate: this.beginEndDate
      }
      this.loading = true
      orderList(params).then(res => {
        this.loading = false
        if (res.code === 0) {
          const { records, total } = res.result
          this.tableData = records
          this.total = total
        }
      })
    },
    handleLeaseWay (val) {
      // 1 到期归还 2 到期归还 3 租满即送 4到期归还
      switch (val) {
        case 1:
          return '到期归还'
        case 2:
          return '到期归还'
        case 3:
          return '租满即送'
        case 4:
          return '到期归还'
        default:
          break
      }
    },
    // 处理时间
    handleDate (str) {
      return str ? formatDate(str) : '-'
    },
    orderBlur (val) {
      this.orderId = val
    },
    deviceIdBlur (val) {
      this.deviceId = val
    },
    companyCustomerNameBlur (val) {
      this.companyCustomerName = val
    },
    customerNameBlur (val) {
      this.customerName = val
    },
    phoneBlur (val) {
      this.phone = val
    },
    authPhoneBlur (val) {
      this.authPhone = val
    },
    goodsGradeChange (val) {
      this.goodsGrade = val
    },
    dateChange (beginStartDate, beginEndDate) {
      this.beginStartDate = beginStartDate
      this.beginEndDate = beginEndDate
    },
    handleOrderStatus (val) {
      return orderStatus(val)
    },
    search () {
      this.curPage = 1
      // 限制只有这些条件才能查询
      if (this.orderId || this.deviceId || this.customerName || this.phone || this.authPhone || this.companyCustomerName) {
        this.getList()
      } else {
        this.$message.warning('已限制只有前6个搜索条件中任意1个可以搜索')
      }
    },
    // 报修
    async repairs (info) {
      // 04416266887548772043
      let isDaKe = false
      const skuRes = await getOrderTypeBySkuId({ skuId: info.goodsSn })
      if (skuRes.data) {
        if (skuRes.data.orderType === 3) {
          isDaKe = true
        } else {
          isDaKe = false
        }
      } else {
        isDaKe = false
      }
      // 订单为取消等状态不能创建工单
      const statusArr = [4, 5, 6, 12]
      // 已关闭的订单如果是大客业务也可以创建工单
      if (statusArr.includes(info.orderStatus) && !isDaKe) {
        let html = ''
        switch (info.orderStatus) {
          case 4:
            html = '用户取消订单'
            break
          case 5:
            html = '订单过期系统自动取消'
            break
          case 6:
            html = '用户删除订单'
            break
          case 12:
            html = '审核不通过'
            break
        }
        this.$message.error(`订单状态为：${html}，无法创建工单`)
        return
      }
      this.info = info
      this.addShow = true
      // 检查是否已有工单
      checkHaveHandleOrder({ orderCode: info.orderId }).then(res => {
        if (res.code === '1' && res.data) {
          const { code, statusName, serviceName, remoteName } = res.data
          this.$message({
            message: `工单:${code}，状态:${statusName}，客服:${serviceName || '-'}，售后:${remoteName || '-'}`,
            type: 'warning',
            duration: 5000
          })
        }
      })
      // 商家管理模式提醒
      storeModeWarn({ storeId: info.companyId, categoryId: info.categoryId }).then(res => {
        this.$message({
          message: res.data,
          type: 'warning',
          duration: 5000
        })
      })
    },
    // 补偿
    onCompensate (info, type) {
      this.dialogType = type
      this.compensateAddShow = true
      this.info = info
    },
    // 有误订单
    onErrorOrder (info, type) {
      this.dialogType = type
      this.errorOrderAddShow = true
      this.info = info
    },
    close () {
      this.addShow = false
      this.compensateAddShow = false
      this.errorOrderAddShow = false
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.orderId = ''
      this.deviceId = ''
      this.customerName = ''
      this.companyCustomerName = ''
      this.phone = ''
      this.authPhone = ''
      this.goodsGrade = ''
      this.beginStartDate = ''
      this.beginEndDate = ''
      this.date = []
      this.tableData = []
      this.total = 0
      // this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
