<template>
  <dialogComp
    :title="dialogType === 'add' ? '客户补偿记录新增' : '客户补偿记录修改'"
    width="740px"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <baseInfo :info="info" />
    <el-form :model="formData" :rules="rules" ref="formData" label-width="90px">
      <div class="flex form_item_box">
        <el-form-item label="补偿原因:" prop="reason">
          <cascader
            :data="reasonList"
            :hval="
              filterArrNull([
                formData.reason,
                formData.reasonOne,
                formData.reasonTwo,
                formData.reasonThree
              ])
            "
            @change="reasonChange"
            placeholder="请选择补偿原因"
          />
        </el-form-item>
        <div class="flex num_input">
          <inputFormComp
            label="耽误时长:"
            prop="delayNum"
            inputType="number"
            unit="台"
            :hval="formData.delayNum"
            @blur="delayNumBlur"
            placeholder="台数"
            :clearable="false"
          />
          <inputFormComp
            class="day_num"
            label=""
            prop="delayDays"
            inputType="number"
            unit="日"
            :hval="formData.delayDays"
            @blur="delayDaysBlur"
            placeholder="天数"
            :clearable="false"
          />
        </div>
      </div>
      <div class="flex form_item_box">
        <selectFormComp
          label="工单号:"
          prop="orderId"
          :data="orderIdList"
          :optionsParams="{ value: 'orderId', label: 'code' }"
          :hval="formData.orderId"
          placeholder="请选择工单号"
          @change="orderIdChange"
        />
        <selectFormComp
          label="补偿方式:"
          prop="compensateMode"
          :data="compensateModeList"
          :optionsParams="{ value: 'code', label: 'name' }"
          :hval="formData.compensateMode"
          placeholder="请选择补偿方式"
          @change="compensateModeChange"
        />
      </div>
      <div class="flex form_item_box">
        <inputFormComp
          label="补偿金额:"
          prop="compensatePrice"
          inputType="number"
          :hval="formData.compensatePrice"
          @blur="compensatePriceBlur"
          placeholder="请输入补偿金额"
        />
        <el-form-item label="补偿日期:" prop="compensateDate">
          <el-date-picker
            style="width:100%"
            size="small"
            v-model="formData.compensateDate"
            type="date"
            placeholder="选择日期"
            @change="compensateDateChange"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <inputFormComp
        label="详细说明:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入详细说明"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import baseInfo from '../comp/baseInfo'
import {
  dictThree,
  serviceVisitAboutOrder,
  compensateAdd,
  compensateEdit
} from '@/api'
import { formatDate } from '@/utils/common'
export default {
  components: {
    baseInfo
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      reasonList: [],
      compensateModeList: [],
      orderIdList: [],
      formData: {
        reason: '',
        reasonOne: '',
        reasonTwo: '',
        reasonThree: '',
        delayNum: '',
        delayDays: '',
        orderId: '',
        compensateMode: '',
        compensatePrice: '',
        compensateDate: '',
        remark: ''
      },
      rules: {
        reason: [
          { required: true, message: '请选择补偿原因', trigger: 'change' }
        ],
        delayNum: [{ required: true, message: '请输入台数', trigger: 'blur' }],
        delayDays: [{ required: true, message: '请输入天数', trigger: 'blur' }],
        compensateMode: [
          { required: true, message: '请选择补偿方式', trigger: 'change' }
        ],
        compensatePrice: [
          { required: true, message: '请输入补偿金额', trigger: 'blur' }
        ],
        compensateDate: [
          { required: true, message: '请选择补偿日期', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {
    this.getSelectData()
  },
  watch: {
    isShow (val) {
      if (val) {
        if (this.dialogType === 'edit') {
          const {
            reason,
            reasonOne,
            reasonTwo,
            reasonThree,
            delayNum,
            delayDays,
            orderId,
            compensateMode,
            compensatePrice,
            compensateDate,
            remark
          } = this.info
          this.formData = {
            reason,
            reasonOne,
            reasonTwo,
            reasonThree,
            delayNum,
            delayDays,
            orderId: orderId === '0' ? '' : orderId,
            compensateMode,
            compensatePrice,
            compensateDate,
            remark
          }
        }
      }
    }
  },
  methods: {
    // 获取补偿原因/获取补偿方式/获取相关工单
    async getSelectData () {
      const res1 = await dictThree({ code: 101453 })
      const res2 = await dictThree({ code: 101454 })
      // const orderId = this.info.orderId
      const res3 = await serviceVisitAboutOrder({
        orderCode: '04416228824107100665'
      })
      this.reasonList = res1.data
      this.compensateModeList = res2.data
      this.orderIdList = res3.data
    },
    // 封装方法，删掉数组中的null的数据
    filterArrNull (arr) {
      return arr.filter(item => item !== null && item !== '0' && item !== '')
    },
    reasonChange (val) {
      switch (val.length) {
        case 0:
          this.formData.reason = ''
          this.formData.reasonOne = ''
          this.formData.reasonTwo = ''
          this.formData.reasonThree = ''
          break
        case 1:
          this.formData.reason = val[0]
          this.formData.reasonOne = ''
          this.formData.reasonTwo = ''
          this.formData.reasonThree = ''
          break
        case 2:
          this.formData.reason = val[0]
          this.formData.reasonOne = val[1]
          this.formData.reasonTwo = ''
          this.formData.reasonThree = ''
          break
        case 3:
          this.formData.reason = val[0]
          this.formData.reasonOne = val[1]
          this.formData.reasonTwo = val[2]
          this.formData.reasonThree = ''
          break
        case 4:
          this.formData.reason = val[0]
          this.formData.reasonOne = val[1]
          this.formData.reasonTwo = val[2]
          this.formData.reasonThree = val[3]
          break
      }
    },
    delayNumBlur (val) {
      this.formData.delayNum = val
    },
    delayDaysBlur (val) {
      this.formData.delayDays = val
    },
    orderIdChange (val) {
      this.formData.orderId = val
    },
    compensateModeChange (val) {
      this.formData.compensateMode = val
    },
    compensateDateChange (val) {
      this.formData.compensateDate = formatDate(val)
    },
    compensatePriceBlur (val) {
      this.formData.compensatePrice = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { orderId, id, orderCode } = this.info
          if (this.dialogType === 'add') {
            const params = {
              orderCode: orderId, // 这是从订单列表取得
              ...this.formData
            }
            compensateAdd(params).then(_ => {
              this.$message.success('新增成功')
              this.close()
            })
          } else {
            compensateEdit({ ...this.formData, orderCode, id }).then(_ => {
              this.$message.success('修改成功')
              this.close()
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form_item_box {
  > div {
    width: 50%;
  }
  .day_num {
    position: relative;
    right: 100px;
  }
}
/deep/ .num_input {
  .el-input {
    position: relative;
    top: 5px;
  }
  .el-input__inner {
    width: 80px;
  }
}
</style>
